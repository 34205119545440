<template>
  <div id="formulario-edit-info">   
    <ExibirErrosConcept :errorsValidacao="errorsValidacao" ref="errosComponent"></ExibirErrosConcept>
    <vx-card>
        <div>
             <vs-button class="ml-auto mt-2" size="small" @click="gravar" color="success" v-if="isAdministrador">Gravar</vs-button>
             <vs-button class="ml-4 mt-2" size="small" @click="excluir" v-show="isDeletable && isAdministrador" color="danger">Excluir</vs-button>            
             <vs-button class="ml-4 mt-2" size="small" type="border" color="dark" @click="voltar">Voltar</vs-button>  
        </div>
        <vs-tabs alignment="fixed" style="height: calc(80vh); overflow-y: auto;">
            <vs-tab label="Dados Básicos">               
                <div class="vx-row">
                    <div class="vx-col w-1/3"><label class="vs-input--label">Razão Social</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">Nome Fantasia</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">Nome Central</label></div>     
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-1/3 flex">
                        <vs-input class="w-full" v-model="data_local.nome" :maxlength="255"/>   
                    </div>     
                    <div class="vx-col w-1/3 flex">
                        <vs-input class="w-full" v-model="data_local.nomeFantasia" :maxlength="255"/>   
                    </div>     
                    <div class="vx-col w-1/3 flex">
                        <vs-input class="w-full" v-model="data_local.nomeCentral" :maxlength="255"/>   
                    </div>     
                </div>    

                <div class="vx-row mt-2">
                    <div class="vx-col w-5/6"><label class="vs-input--label">Endereço</label></div>     
                    <div class="vx-col w-1/6"><label class="vs-input--label">Número</label></div>     
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-5/6">
                        <vs-input class="w-full" v-model="data_local.endereco" :maxlength="500"/> 
                    </div>                 
                    <div class="vx-col w-1/6">
                        <vs-input class="w-full" v-model="data_local.numeroEndereco" :maxlength="45"/> 
                    </div>                 
                </div>
                <div class="vx-row mt-2">
                    <div class="vx-col w-1/3"><label class="vs-input--label">Complemento</label></div>         
                    <div class="vx-col w-1/3"><label class="vs-input--label">Bairro</label></div>         
                    <div class="vx-col w-1/3"><label class="vs-input--label">CEP</label></div>         
                </div>
                <div class="vx-row">
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.complementoEndereco" :maxlength="100"/> 
                    </div>
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.bairro" :maxlength="100"/> 
                    </div>
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.cep" :maxlength="9"/> 
                    </div>     
                </div>
                <div class="vx-row mt-2">                    
                    <div class="vx-col w-1/2"><label class="vs-input--label">Cidade</label></div>     
                    <div class="vx-col w-1/2"><label class="vs-input--label">Estado</label></div>                 
                </div>    
                <div class="vx-row">                    
                    <div class="vx-col w-1/2">
                        <vs-input class="w-full" v-model="data_local.cidade" :maxlength="255"/> 
                    </div>     
                    <div class="vx-col w-1/2">
                        <v-select                                               
                            :options="CONSTANTE_UFS" 
                            v-model="data_local.estado"    
                            :clearable="false"
                            :reduce="r => r.data"                                                                         
                            class="vs-input--label mr-2">
                        </v-select>   
                    </div>     
                </div>    

                <div class="vx-row mt-3">
                    <div class="vx-col w-1/3"><label class="vs-input--label">CNPJ</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">Nome Logo</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">Status</label></div>     
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-1/3 flex">
                        <vs-input class="w-full" v-model="data_local.CNPJ" :maxlength="20"/>   
                    </div>     
                    <div class="vx-col w-1/3 flex">
                        <vs-input class="w-full" v-model="data_local.imagemLogo" :maxlength="255"/>   
                    </div>     
                    <div class="vx-col w-1/3 flex">
                        <v-select                                                       
                            :reduce="m => m.data"                            
                            :options="CONSTANTE_TIPOS_STATUS_EMPRESA" 
                            v-model="data_local.status"                      
                            class="w-full">
                        </v-select> 
                    </div>     
                </div>  
                <div class="vx-row mt-3">
                    <div class="vx-col w-1/6"><label class="vs-input--label">Dia do Vencimento</label></div>     
                    <div class="vx-col w-1/6"><label class="vs-input--label">Preço Diferenciado por Versão</label></div>     
                    <div class="vx-col w-1/6"><label class="vs-input--label">Acesso a versão PRO</label></div>   
                    <div class="vx-col w-1/6"><label class="vs-input--label">Envia SMS</label></div>      
                    <div class="vx-col w-1/6"><label class="vs-input--label">Tem Horário de Verão ?</label></div>   
                    <div class="vx-col w-1/6"><label class="vs-input--label">Horas Desatualizado ?</label></div>   
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-1/6 flex">
                        <vs-input-number min="1" max="30"  class="w-full" v-model="data_local.diaVencimento" />   
                    </div>     
                    <div class="vx-col w-1/6 flex">
                        <vs-checkbox v-model="data_local.precoDiferenciadoPorVersao" color="success" class="w-full"></vs-checkbox>
                    </div>     
                    <div class="vx-col w-1/6 flex">
                        <vs-checkbox v-model="data_local.acessoVersaoPRO" color="success" class="w-full"></vs-checkbox>
                    </div>     
                    <div class="vx-col w-1/6 flex">
                        <vs-checkbox v-model="data_local.enviarSMS" color="success" class="w-full"></vs-checkbox>  
                    </div>         
                    <div class="vx-col w-1/6 flex">
                        <vs-checkbox v-model="data_local.temHorarioDeVerao" color="success" class="w-full"></vs-checkbox>  
                    </div>    
                    <div class="vx-col w-1/6 flex">
                        <vs-input-number min="0"  class="w-full" v-model="data_local.horasParaEnviarAvisoDesatualizado" />   
                    </div>           
                </div>     
                <div class="vx-row  mt-3">
                    <div class="vx-col w-1/2"><label class="vs-input--label">Faixa Pro</label></div>     
                    <div class="vx-col w-1/2"><label class="vs-input--label">Faixa Basic</label></div>     
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-1/2 flex">
                        <vue-autonumeric  class="vs-inputx vs-input--input normal hasValue w-full" v-model="data_local.faixa1" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric>                                                            
                        <vue-autonumeric  class="vs-inputx vs-input--input normal hasValue w-full" v-model="data_local.faixa2" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric>                                                           
                        <vue-autonumeric  class="vs-inputx vs-input--input normal hasValue w-full" v-model="data_local.faixa3" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric>                                                           
                    </div>     
                    <div class="vx-col w-1/2 flex">
                        <vue-autonumeric  class="vs-inputx vs-input--input normal hasValue w-full" v-model="data_local.faixa1Basic" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric>                                                            
                        <vue-autonumeric  class="vs-inputx vs-input--input normal hasValue w-full" v-model="data_local.faixa2Basic" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric>                                                           
                        <vue-autonumeric  class="vs-inputx vs-input--input normal hasValue w-full" v-model="data_local.faixa3Basic" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric>                                                           
                    </div>     
                </div>  
                <div class="vx-row mt-3">
                    <div class="vx-col w-1/3"><label class="vs-input--label">Data Início Contrato</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">Meses Carência</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">Quantidade Mínima de Veículos</label></div>     
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-1/3 flex">
                        <flat-pickr v-model="data_local.dataInicioContrato" :config="configDatePicker"/>
                    </div>     
                    <div class="vx-col w-1/3 flex">
                        <vue-autonumeric  class="vs-inputx vs-input--input normal hasValue w-full" v-model="data_local.mesesCarencia" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'0' }]"></vue-autonumeric>
                    </div>     
                    <div class="vx-col w-1/3 flex">
                        <vue-autonumeric  class="vs-inputx vs-input--input normal hasValue w-full" v-model="data_local.qtdMinimaVeiculos" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'0' }]"></vue-autonumeric>
                    </div>     
                </div>     
                <div class="vx-row mt-3">
                    <div class="vx-col w-1/3"><label class="vs-input--label">Email Monitor</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">Site da Plataforma</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">Email Monitoramento</label></div>     
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-1/3 flex">
                        <vs-input class="w-full" v-model="data_local.emailMonitor" :maxlength="255"/>   
                    </div>     
                    <div class="vx-col w-1/3 flex">
                        <vs-input class="w-full" v-model="data_local.urlSistema" :maxlength="255"/>   
                    </div>     
                    <div class="vx-col w-1/3 flex">
                        <vs-input class="w-full" v-model="data_local.emailMonitoramento" :maxlength="255"/>   
                    </div>     
                </div>     
                <div class="vx-row mt-3">
                    <div class="vx-col w-1/3"><label class="vs-input--label">Email Financeiro</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">Telefone</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">Whatsapp</label></div>     
                    
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-1/3 flex">
                        <vs-input class="w-full" v-model="data_local.emailFinanceiro" :maxlength="255"/>   
                    </div>     
                    <div class="vx-col w-1/3 flex">
                        <vs-input class="w-full" v-model="data_local.telefoneEmpresa" :maxlength="100"/>   
                    </div>     
                    <div class="vx-col w-1/3 flex">
                        <vs-input class="w-full" v-model="data_local.numeroWhatsapp" :maxlength="100"/>   
                    </div>     
                    
                </div>     

                <div class="vx-row mt-3">
                    <div class="vx-col w-1/2"><label class="vs-input--label">Senha Webservice</label></div>     
                    <div class="vx-col w-1/2"><label class="vs-input--label">SAC</label></div>     
                </div>    
                <div class="vx-row">            
                    <div class="vx-col w-1/2 flex">
                        <vs-input class="w-full" v-model="data_local.senhaWebservice" :maxlength="255" :readonly="true"/>   
                    </div>     
                    <div class="vx-col w-1/2 flex">
                        <vs-input class="w-full" v-model="data_local.gefimIdUnidadeOperacional" :maxlength="255" placeholder="Unidade Operacional"/>   
                        <vs-input class="w-full" v-model="data_local.gefimIdInstituicao" :maxlength="255" placeholder="Instituição"/> 
                        <vs-input class="w-full" v-model="data_local.gefimIdContaPlanoConta" :maxlength="255" placeholder="Conta Plano Conta"/> 
                    </div>     
                </div>     

                <div class="vx-row mt-3">            
                    <div class="vx-col w-1/2"><label class="vs-input--label">App Android</label></div>     
                    <div class="vx-col w-1/2"><label class="vs-input--label">App iOS</label></div>     
                </div>    
                <div class="vx-row"> 
                    <div class="vx-col w-1/2 flex">
                        <vs-input class="w-full" v-model="data_local.urlAPPAndroid" :maxlength="500"/>   
                    </div> 
                    <div class="vx-col w-1/2 flex">
                        <vs-input class="w-full" v-model="data_local.urlAPPiOS" :maxlength="500"/>   
                    </div>     
                </div>     

                <div class="vx-row mt-3">            
                    <div class="vx-col w-1/3"><label class="vs-input--label">URL Link Temporario</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">URL Api Assinatura Digital</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">Token API Assinatura Digital</label></div>     
                </div>    
                <div class="vx-row"> 
                    <div class="vx-col w-1/3 flex">
                        <vs-input class="w-full" v-model="data_local.urlLinkTemporario" :maxlength="500"/>   
                    </div> 
                    <div class="vx-col w-1/3 flex">
                        <vs-input class="w-full" v-model="data_local.urlAPIAssinaturaDigital" :maxlength="500"/>   
                    </div>     
                    <div class="vx-col w-1/3 flex">
                        <vs-input class="w-full" v-model="data_local.tokenAPIAssinaturaDigital" :maxlength="500"/>   
                    </div>     
                </div>    
            </vs-tab>
            <vs-tab :label="'Anexos (' + dadosDaGridAnexos.length + ')'" v-if="data_local.idEmpresa">
                <div class="vx-row">
                    <div class="vx-col md:w-3/4 sm:w-3/4 w-full">
                        
                        <vx-card class="mt-2" v-if="dadosDaGridAnexos.length">
                            <div class="flex items-center flex-start">
                                <vs-button class="mt-2 mr-2 mb-4" size="small" @click="verDocumento" color="primary" icon="search">VER</vs-button> 
                                <vs-button class="mt-2 mb-4" size="small" @click="excluirAnexo" color="danger" icon="delete">EXCLUIR</vs-button> 
                            </div>
                            
                            <ag-grid-vue
                                ref="agGridTableAnexos"        
                                :gridOptions="configuracaoGridAnexo.gridOptions"
                                class="ag-grid-table ag-grid-altura"
                                :columnDefs="configuracaoGridAnexo.columnDefs"
                                :defaultColDef="configuracaoGridAnexo.defaultColDef"
                                :rowData="dadosDaGridAnexos"
                                :headerHeight="25"
                                :rowHeight="25"
                                rowSelection="single"
                                @grid-ready="onGridReadyAnexo"                            
                                colResizeDefault="shift"                    
                                :animateRows="true"
                                :floatingFilter="false"
                                :pagination="false"
                                :paginationAutoPageSize="false"
                                :suppressPaginationPanel="true"
                                >
                            </ag-grid-vue>
                            
                            
                        </vx-card> 
                    </div>
                    <div class="vx-col md:w-1/4 sm:w-1/4 w-full flex-items-center">
                        <vx-card class="mt-2">
                            <vs-upload :action="getUrlPost" @on-success="successUploadFile" @on-delete="limparMsgErroUpload"
                                    text="Anexar ..." :data="dadosUpload" limit="1" multiple="false"  fileName="nomeArquivo"
                                    automatic single-upload/>
                            <vs-alert closable title="Atenção" color="warning" :active.sync="exibirMensagemResultadoUpload">{{mensagemResultadoUpload}}</vs-alert>
                        </vx-card> 
                    </div>
                </div>
            </vs-tab>
        </vs-tabs>
    </vx-card>  
  </div>
</template>

<script>
import axios from "@/axios.js"
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Portuguese as PortugueseLocale} from 'flatpickr/dist/l10n/pt.js';
import { AgGridVue } from "ag-grid-vue"
import '@/assets/concept/scss/tabelaBasica.scss'
import generico from "@/generico.js"

import { CONSTANTE_UFS } from '@/constantesComboBox.js'
const URL_SERVLET = "/EmpresaRest";
const NAME_ROUTE_SEARCH = "cadastro-empresas-pesquisar";

export default { 
  components: {  
    VueAutonumeric,
    flatPickr,
    AgGridVue
  },      
  props: {
    registroOriginal: {
      type: Object,
      required: false,
      default: function () {
            return {nome: null, CNPJ: null, imagemLogo: null, status:null, diaVencimento:null, faixa1:null, faixa2:null, faixa3:null, nomeFantasia:null, emailMonitor:null, 
            urlSistema:null, enviarSMS:false,  emailMonitoramento:null, temHorarioDeVerao:false, emailFinanceiro:null, gefimIdUnidadeOperacional:null, gefimIdInstituicao:null, 
            gefimIdContaPlanoConta:null, faixa1Basic:null, faixa2Basic:null, faixa3Basic:null, telefoneEmpresa:null,  senhaWebservice: null, precoDiferenciadoPorVersao: true, 
            acessoVersaoPRO: true, nomeCentral: null, urlAPPAndroid: null, urlAPPiOS: null, numeroWhatsapp: null, horasParaEnviarAvisoDesatualizado: 96,
            urlLinkTemporario: null, urlAPIAssinaturaDigital: null, tokenAPIAssinaturaDigital: null, dataInicioContrato: null, qtdMinimaVeiculos: null, mesesCarencia: null, 
            endereco: null, complementoEndereco: null, numeroEndereco: null, bairro: null, cidade: null, estado: null, cep: null }
       }
    },
  },
  data() {
    return {        
        errorsValidacao: [],  
        data_local: JSON.parse(JSON.stringify(this.registroOriginal)),           
        configDatePicker: {          
            altInput: true,
            altFormat: "d-m-Y",
            dateFormat: "Y/m/d", 
            altInputClass: "vs-inputx vs-input--input normal hasValue text-center",
            locale: PortugueseLocale
        },
        dadosDaGridAnexos: [],
        configuracaoGridAnexo: { 
          gridApi: null, gridOptions: {}, 
          defaultColDef: { sortable: true, resizable: true, suppressMenu: true  },
          columnDefs: [                      
            { headerName: 'ID',  field: 'id', width: 30},        
            { headerName: 'Arquivo',  field: 'nomeDocumento', width: 400},        
            { headerName: 'Criado às',  field: 'dataUpload', width: 200}            
          ]
        },
        dadosUpload: {
           empresaId: this.registroOriginal.idEmpresa,                    
           destino: "empresa",           
           outputFormat: 'JSON',
           nomeArquivo: 'Teste seção 2'
        },
        mensagemResultadoUpload: null,
        exibirMensagemResultadoUpload: false,
    }
  },  
  created(){
    this.CONSTANTE_TIPOS_STATUS_EMPRESA = [
        {label:"", data:""},
		{label:"ATIVO", data:"ATIVO"},
		{label:"INATIVO", data:"INATIVO"},
		{label:"BLOQUEADO", data:"BLOQUEADO"},
		{label:"INADIMPLENTE", data:"INADIMPLENTE"},
		{label:"TESTE_FINALIZADO", data:"TESTE_FINALIZADO"}
    ];

    this.CONSTANTE_UFS = CONSTANTE_UFS;
  }, 
  mounted() {

    if (this.data_local.hasOwnProperty('idEmpresa')) {
        this.fetchListaDocumentos();
    }
      
  },
  computed: {        
    isDeletable() {
       return this.data_local != null && this.data_local.hasOwnProperty("idEmpresa");
    },    
    getUrlPost() {
      return axios.defaults.baseURL + "/UploadDocumentos?processUploadedFile";
    },  
    isAdministrador() {
       return this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR';
    }, 
  },
  methods: {  
    excluir() {
        this.$vs.dialog({
          type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja excluir ?', acceptText: 'Sim', cancelText: 'Não', accept: this.excluirRequest
        });
    },
    excluirRequest() {
        this.$vs.loading();  
        const params = new URLSearchParams();        
        params.append('outputFormat', 'JSON');
        params.append('method', 'Delete');      
        params.append('id', this.data_local.idEmpresa);   
                
        axios.post(URL_SERVLET, params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                } else {
                    this.$router.push({ name: NAME_ROUTE_SEARCH })
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    },
    gravar() {        
        if (this.formularioValido()) { 
            this.$vs.loading();  
            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');
            
            if (this.registroOriginal.hasOwnProperty("idEmpresa")) {
                params.append('method', 'Update');      
                params.append('id', this.data_local.idEmpresa);      
            }  else {
                params.append('method', 'Insert');      
            }  

            params.append('nome', this.data_local.nome);      
            params.append('CNPJ', this.data_local.CNPJ);      
            params.append('imagemLogo', this.data_local.imagemLogo);      
            params.append('status', this.data_local.status);      
            params.append('diaVencimento', this.data_local.diaVencimento);      
            params.append('faixa1', this.data_local.faixa1);      
            params.append('faixa2', this.data_local.faixa2);      
            params.append('faixa3', this.data_local.faixa3);      
            params.append('nomeFantasia', this.data_local.nomeFantasia);      
            params.append('emailMonitor', this.data_local.emailMonitor);      
            params.append('urlSistema', this.data_local.urlSistema);      
            params.append('enviarSMS', this.data_local.enviarSMS);      
            params.append('emailMonitoramento', this.data_local.emailMonitoramento);      
            params.append('temHorarioDeVerao', this.data_local.temHorarioDeVerao);      
            params.append('emailFinanceiro', this.data_local.emailFinanceiro);      
            if (this.data_local.gefimIdUnidadeOperacional) {
                params.append('gefimIdUnidadeOperacional', this.data_local.gefimIdUnidadeOperacional);      
            }
            if (this.data_local.gefimIdInstituicao) {
                params.append('gefimIdInstituicao', this.data_local.gefimIdInstituicao);      
            }
            if (this.data_local.gefimIdContaPlanoConta) {
                params.append('gefimIdContaPlanoConta', this.data_local.gefimIdContaPlanoConta);      
            }
            params.append('faixa1Basic', this.data_local.faixa1Basic);      
            params.append('faixa2Basic', this.data_local.faixa2Basic);      
            params.append('faixa3Basic', this.data_local.faixa3Basic);      
            params.append('telefoneEmpresa', this.data_local.telefoneEmpresa);      
            params.append('precoDiferenciadoPorVersao', this.data_local.precoDiferenciadoPorVersao);      
            params.append('acessoVersaoPRO', this.data_local.acessoVersaoPRO);      
            params.append('nomeCentral', this.data_local.nomeCentral);      
            params.append('urlAPPAndroid', this.data_local.urlAPPAndroid);      
            params.append('urlAPPiOS', this.data_local.urlAPPiOS);      
            params.append('numeroWhatsapp', this.data_local.numeroWhatsapp);
            params.append('horasParaEnviarAvisoDesatualizado', this.data_local.horasParaEnviarAvisoDesatualizado);
            
            if (this.data_local.dataInicioContrato) {
                params.append('dataInicioContrato', this.data_local.dataInicioContrato);
                params.append('qtdMinimaVeiculos', this.data_local.qtdMinimaVeiculos);
                params.append('mesesCarencia', this.data_local.mesesCarencia);
            }
            if (this.data_local.urlLinkTemporario) {
                params.append('urlLinkTemporario', this.data_local.urlLinkTemporario);
            }

            if (this.data_local.urlAPIAssinaturaDigital) {
                params.append('urlAPIAssinaturaDigital', this.data_local.urlAPIAssinaturaDigital);
            }

            if (this.data_local.tokenAPIAssinaturaDigital) {
                params.append('tokenAPIAssinaturaDigital', this.data_local.tokenAPIAssinaturaDigital);
            }

            params.append('endereco', this.data_local.endereco);     
            params.append('bairro', this.data_local.bairro);
            params.append('cidade', this.data_local.cidade);     
            params.append('estado', this.data_local.estado);     
            params.append('cep', this.data_local.cep);     

            if (this.data_local.numeroEndereco) {
                params.append('numeroEndereco', this.data_local.numeroEndereco);
            }

            if (this.data_local.complementoEndereco) {
                params.append('complementoEndereco', this.data_local.complementoEndereco);
            }
                       
            axios.post(URL_SERVLET, params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    /* eslint-disable no-console */ console.log(response.data);
                    if (response.data.hasOwnProperty("error")) {
                            this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                    } else {
                        this.$router.push({ name: NAME_ROUTE_SEARCH })
                    }                   
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
            })  
        }
         
    },
    voltar() {
        this.$router.push({
            name: NAME_ROUTE_SEARCH                     
        })
    },
    formularioValido() {
        this.$refs.errosComponent.iniciar();
        this.errorsValidacao = [];
      
        if (!this.data_local.nome) { this.errorsValidacao.push("Nome obrigatório"); }
        if (!this.data_local.CNPJ) { this.errorsValidacao.push("CNPJ obrigatório"); }
        if (!this.data_local.imagemLogo) { this.errorsValidacao.push("Imagem Logo obrigatório"); }
        if (!this.data_local.status) { this.errorsValidacao.push("Status obrigatório"); }
        if (!this.data_local.diaVencimento) { this.errorsValidacao.push("Dia Vencimento obrigatório"); }
        if (!this.data_local.faixa1) { this.errorsValidacao.push("Faixa 1 PRO obrigatório"); }
        if (!this.data_local.faixa2) { this.errorsValidacao.push("Faixa 2 PRO obrigatório"); }
        if (!this.data_local.faixa3) { this.errorsValidacao.push("Faixa 3 PRO obrigatório"); }
        if (!this.data_local.faixa1Basic) { this.errorsValidacao.push("Faixa 1 BASIC obrigatório"); }
        if (!this.data_local.faixa2Basic) { this.errorsValidacao.push("Faixa 2 BASIC obrigatório"); }
        if (!this.data_local.faixa3Basic) { this.errorsValidacao.push("Faixa 3 v obrigatório"); }
        if (!this.data_local.nomeFantasia) { this.errorsValidacao.push("Nome Fantasia obrigatório"); }
        if (!this.data_local.emailMonitor) { this.errorsValidacao.push("Email Monitor obrigatório"); }
        if (!this.data_local.urlSistema) { this.errorsValidacao.push("URL Sistema obrigatório"); }
        if (!this.data_local.emailMonitoramento) { this.errorsValidacao.push("Email Monitoramento obrigatório"); }
        if (!this.data_local.emailFinanceiro) { this.errorsValidacao.push("Email Financeiro obrigatório"); }
        if (!this.data_local.telefoneEmpresa) { this.errorsValidacao.push("Telefone Empresa obrigatório"); }
        if (!this.data_local.nomeCentral) { this.errorsValidacao.push("Nome da Central obrigatório"); }
        if (!this.data_local.urlAPPAndroid) { this.errorsValidacao.push("URL App Android obrigatório"); }
        if (!this.data_local.urlAPPiOS) { this.errorsValidacao.push("URL App iOS obrigatório"); }

        
        if (this.data_local.dataInicioContrato) {                
            if (!this.data_local.qtdMinimaVeiculos) { this.errorsValidacao.push("Quantidade mínuma de veículos obrigatório"); }
            if (!this.data_local.mesesCarencia) { this.errorsValidacao.push("Meses de carância obrigatório"); }                
        }            

        if (!this.data_local.urlAPIAssinaturaDigital && this.data_local.tokenAPIAssinaturaDigital) {
            this.errorsValidacao.push("URL da API de assinatura digital obrigatório");                 
        }

        if (this.data_local.urlAPIAssinaturaDigital && !this.data_local.tokenAPIAssinaturaDigital) {
            this.errorsValidacao.push("Token assinatura digital obrigatório");                 
        }

        if (!this.data_local.endereco) {
            this.errorsValidacao.push("Endereço obrigatório");
        }

        if (!this.data_local.bairro) {
            this.errorsValidacao.push("Bairro obrigatório");
        }

        if (!this.data_local.cidade) {
            this.errorsValidacao.push("Cidade obrigatório");
        }

        if (!this.data_local.estado) {
            this.errorsValidacao.push("Estado obrigatório");
        }
        
        if (!this.data_local.cep) {
            this.errorsValidacao.push("CEP obrigatório");
        } else if (!generico.isCEPValido(this.data_local.cep)) {
            this.errorsValidacao.push("CEP inválido");
        }

        if (!this.errorsValidacao.length) {
            return true;
        }   
        return false;
    },
    successUploadFile(evento){              
        var resultadoJSON = JSON.parse(evento.target.response);
        this.mensagemResultadoUpload = resultadoJSON.mensagem;
        this.exibirMensagemResultadoUpload = true;
        this.fetchListaDocumentos(); 
    },
    limparMsgErroUpload() {
      this.mensagemResultadoUpload = null;
      this.exibirMensagemResultadoUpload = false;
    },
    verDocumento() { 
        var itemSelecionados = this.configuracaoGridAnexo.gridApi.getSelectedRows();

        if (itemSelecionados && itemSelecionados.length > 0) {
            let ultimoIndice = itemSelecionados[0].nomeDocumento.lastIndexOf(".") ;
            if ( ultimoIndice != -1) {
                window.open("https://documentos-empresa-setup.s3.amazonaws.com/" + this.registroOriginal.idEmpresa + "/" + itemSelecionados[0].id + "." + itemSelecionados[0].nomeDocumento.substring(ultimoIndice+1), "_blank");    
            } else {
                window.open("https://documentos-empresa-setup.s3.amazonaws.com/" + this.registroOriginal.idEmpresa + "/" + itemSelecionados[0].id, "_blank");    
            }
            
        } else {
            this.$vs.dialog({ color: 'danger', title: 'Erro', text: 'Selecione um anexo', acceptText: 'OK'})
        }
    },
    fetchListaDocumentos() {
        const params = new URLSearchParams();
        params.append('method', 'LISTAR_DOCUMENTOS');
        params.append('outputFormat', 'JSON');
        params.append('idEmpresa', this.registroOriginal.idEmpresa);

        axios.post(URL_SERVLET, params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {                             
                this.dadosDaGridAnexos = response.data;    
        })
        .catch((error) => { 
            /* eslint-disable no-console */ console.log("Erro fetchListaDocumentos " + error);        
        })      
    },  
    onGridReadyAnexo() {
       this.configuracaoGridAnexo.gridApi = this.configuracaoGridAnexo.gridOptions.api;
       this.configuracaoGridAnexo.gridApi.sizeColumnsToFit();      
    },    
    excluirAnexo(){        
        this.$vs.dialog({
            type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja excluir o arquivo ?  '
            , acceptText: 'Sim', cancelText: 'Não', accept: this.excluirAnexoRequest
        });
    },
    excluirAnexoRequest() {
        var itemSelecionados = this.configuracaoGridAnexo.gridApi.getSelectedRows();

        if (itemSelecionados && itemSelecionados.length > 0) {
            let currentObj = this;
            this.$vs.loading(); 

            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');                
            params.append('idDocumento', itemSelecionados[0].id);   
            params.append('nomeDocumento', itemSelecionados[0].nomeDocumento);   
            params.append('idEmpresa', this.registroOriginal.idEmpresa);
            params.append('method', 'EXCLUIR_DOCUMENTOS');      
                    
            axios.post(URL_SERVLET, params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {                
                    this.$router.push({ path: '/' });
                } else {
                    currentObj.$vs.loading.close();

                    if (response.data.mensagem.indexOf("erro") != -1) {
                            this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.mensagem, acceptText: 'OK'})
                    } else {
                        this.fetchListaDocumentos();
                    }                   
                }
            })
            .catch((error) => { 
                currentObj.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
            })  
        } else {
            this.$vs.dialog({ color: 'danger', title: 'Erro', text: 'Selecione um anexo', acceptText: 'OK'})
        }
    }, 
  }
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 300px);
      position: relative;      
    } 
</style>


<style lang="scss" scoped>
  .ag-grid-altura{
    height: calc(100vh - 250px);
  }
</style>